/* eslint-disable prettier/prettier */
import React from 'react'
import { Container } from '../styles/SobreOCursoLayout'

const SobreOCurso = () => (
  <Container>
    <div>
      <span>Sobre o curso</span>
      <h2>TÉCNICO EM TRANSAÇÕES IMOBILIÁRIAS :</h2>
      <p>
        Organiza ações de compra venda e locação de imóveis. 
        Encaminha as documentações referentes a avaliações e registros de transações 
        imobiliárias. Apresenta os imóveis aos clientes potenciais. Identifica e aplica parâmetros 
        de uso e ocupação para imóveis.
      </p>
      <h2>Carga Horaria Curricular</h2>
      <ul>
        <li>↪ 1065</li>
        <ol>
          <li>↪ 865 horas curriculares</li>
          <li>↪ 200 horas de estágio</li>
        </ol>
      </ul>
      <h2>Legalização</h2>
      <ul>
        <li>↪ PORTARIA COFECI / CRECI</li>
        <li>↪ PORTARIA nº 1176/2018 – SEEC/GS</li>
      </ul>
    </div>
  </Container>
)

export default SobreOCurso